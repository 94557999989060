import { Component, OnInit } from "@angular/core";
import { ContentfulService } from "../contentful.service";

declare var $: any;

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.sass"],
})
export class ServicesComponent implements OnInit {
  services: any;
  constructor(private contentful: ContentfulService) {}

  ngOnInit() {
    this.contentful.getContentByType("service").then((data: any) => {
      this.services = data.items.sort(
        (a, b) => a.fields.order - b.fields.order
      );
      console.log(this.services);
    });
  }

  toggleServiceOpen(e) {
    console.log($(e.target));
    $(e.target).parent(".service-box").toggleClass("open");
  }
}

<main (mousewheel)="scrollEvent($event)">
  <header class="splash">
    <div class="image">
<!--      <img-->
<!--        src="./../assets/img/headers/egli_wassertechnik_header_home.jpg"-->
<!--        alt=""-->
<!--      />-->
      <img
              src="./../assets/img/egli_wassertechnik_10_jahre_header.jpg"
              alt=""
            />
    </div>
    <div class="box">
      <!-- <div class="icon">
        <img src="./../assets/icon.svg" alt="">
      </div> -->
      <h1>
        <!-- Ihre Wasserexperten -->
        <!-- <b>Egli</b> Wassertechnik <br>
        <div class="spacer"></div>
        Ihr Sanitär und Wasserfachmann -->
      </h1>
    </div>
  </header>
  <article>
    <section class="categories highlight">
      <div class="container">
        <div class="category col third">
          <div class="icon">
            <img src="./../assets/icon_haustechnik.svg" alt="" />
          </div>
          <h2>
            Haustechnik
          </h2>
          <p>
            Als Ihr zuverlässiger Partner für Installationen im Bereich Haus und
            Wasser bieten wir fair und effizient einen erstklassigen Service von
            der Planung bis zur Umsetzung.
          </p>
        </div>
        <div class="category col third">
          <div class="icon">
            <img src="./../assets/icon_wassertechnik.svg" alt="" />
          </div>
          <h2>
            Wassertechnik
          </h2>
          <p>
            Mit Blick auf eine einwandfreie Trinkwasserqualität und nach dem
            Motto „Wasser ist Leben“ sorgen wir für optimales Trinkwasser, das
            Ihren Ansprüchen gerecht wird.
          </p>
        </div>
        <div class="category col third">
          <div class="icon">
            <img src="./../assets/icon_beratung.svg" alt="" />
          </div>
          <h2>
            Beratung
          </h2>
          <p>
            Als Ihr kompetenter Partner mit langjähriger Berufserfahrung sind
            wir rund um die Uhr für Sie da und beraten Sie jederzeit gern.
          </p>
        </div>
      </div>
    </section>
<!--    <section class="news">-->
<!--      <a-->
<!--        href="https://egli-wassertechnik.ch/vollentsalzer"-->
<!--        rel="noopener"-->
<!--        target="_blank"-->
<!--      >-->
<!--        <div class="text">-->
<!--          <h3>-->
<!--            Neu-->
<!--          </h3>-->
<!--          <h2>-->
<!--            Egli Vollentsalzer-->
<!--          </h2>-->
<!--          <p>-->
<!--            Unser Mischbettharzfilter im Abomodell<br />-->
<!--            <span class="underline">-->
<!--              egli-wassertechnik.ch/vollentsalzer-->
<!--            </span>-->
<!--          </p>-->
<!--        </div>-->
<!--        <div class="image">-->
<!--          <img src="./../../assets/vollentsalzer.jpg" alt="" />-->
<!--        </div>-->
<!--      </a>-->
<!--    </section>-->
    <section class="services">
      <h3>
        Wir arbeiten in folgenden Bereichen
      </h3>
      <div class="container">
        <div class="service-box" (click)="toggleBox($event)">
          <div class="content-container">
            <h2>
              Installation
            </h2>
            <div class="close-button" (click)="closeBox($event)"></div>
            <div class="content">
              <p>
                Um ein optimales Wassersystem für Ihr Unternehmen oder Ihr
                Eigentum zu erhalten, setzen wir auf bewährte Technologien, die
                Ihre Wasserversorgung zuverlässig und langfristig sicherstellen
                und gleichzeitig wirtschaftlich und ökologisch arbeiten. Ihre
                von uns eingebaute Heizungsanlage funktioniert mit modernen und
                gleichzeitig erprobten Verfahren und verbindet so garantierte
                Leistung mit dem Einsparen von Energie.
              </p>
              <p>
                Wir bieten Lösungen für Konzepte zur Wasseraufbereitung, die den
                strengen Anforderungen und Vorgaben Ihres Produktionsbetriebes
                entsprechen und den Vorschriften genügen. Unsere Installationen
                liefern Ihnen hygienisch einwandfreies Trinkwasser für Ihr
                Zuhause. Durch ökologisches Denken realisieren wir
                umweltfreundliche und energiesparende Anlagen und tragen so
                neben der kostengünstigen Umsetzung zur Erhaltung unserer Umwelt
                bei.
              </p>
              <p>
                Unseren Arbeiten geht stets eine professionelle Analyse voraus,
                die den tatsächlichen Zustand aufnimmt und dabei alle
                Erfordernisse sowie Ihre Wünsche und Vorstellungen
                berücksichtigt. Nach Einbeziehung aller Fakten arbeiten wir ein
                speziell zu Ihrem Projekt passendes Angebot aus. Trotz der
                ausschließlichen Verwendung hochwertiger und langlebiger
                Materialien können Sie von uns stets eine preisgünstige Offerte
                erwarten.
              </p>
            </div>
          </div>
          <div class="image">
            <img src="./../assets/img/home/industrie.jpg" alt="" />
          </div>
        </div>
        <div class="service-box" (click)="toggleBox($event)">
          <div class="content-container">
            <h2>
              Renovation
            </h2>
            <div class="close-button" (click)="closeBox($event)"></div>
            <div class="content">
              <p>
                Möchten Sie die Wasserversorgung in Ihrer Wohnimmobilie
                verbessern und modernisieren, da diese nicht mehr aktuell ist?
                Sind die Rohrleitungen veraltet und müssen ausgetauscht werden?
                Möchten Sie mit einer modernen Heizungsanlage Energiekosten
                sparen und so die Umwelt entlasten? Wir erneuern die gesamte
                Wassertechnik Ihres Hauses oder Ihrer Wohnung und bringen diese
                fachgerecht auf den neuesten Stand der Technik. Nach der
                Sanierung durch unser Unternehmen fühlen Sie sich wieder wohl in
                Ihrem Badezimmer.
              </p>
              <p>
                Wir machen aus Ihrem alten Bad einen neues mit zeitgemäßen
                sanitären Einrichtungen und einer Ausstattung, die optimal auf
                Ihre Bedürfnisse zugeschnitten ist und Ihren Wünschen für ein
                schöneres Wohnen entspricht. Verwirklichen Sie mit uns die
                Träume für Ihr neues Zuhause und erfahren Sie so ein völlig
                neues Lebensgefühl.
              </p>
              <p>
                Durch den demographischen Wandel besteht ein erhöhter Bedarf an
                altersgerechten Wohnungen. Lassen Sie notwendige Veränderungen
                vornehmen und bauen Sie die Badezimmer in Ihrer Wohnimmobilie
                mit unserer Hilfe um, um so einen größeren Mieterkreis
                anzusprechen, oder gestalten Sie Ihren Wohnraum Barriere frei.
                Wir unterstützen Sie bei der professionellen Sanierung und
                Aufwertung Ihrer Immobilie, dabei können Sie sich auf eine
                kostengünstige und qualitative Umsetzung verlassen, die einen
                erheblichen Mehrwert für Ihr Eigentum verspricht. Fordern Sie
                unser individuelles Angebot an.
              </p>
            </div>
          </div>
          <div class="image">
            <img src="./../assets/img/home/umbau.jpg" alt="" />
          </div>
        </div>
        <div class="service-box" (click)="toggleBox($event)">
          <div class="content-container">
            <h2>
              Service
            </h2>
            <div class="close-button" (click)="closeBox($event)"></div>
            <div class="content">
              <p>
                Unser Ziel ist die Optimierung der Wasserversorgung und Wasser
                Aufbereitung unter Anerkennung der Bedeutsamkeit des Wassers für
                unseren Planeten und jeden einzelnen von uns. Wir bringen dieses
                wertvolle Gut sauber und sicher in Ihre Firma, in Ihr Zuhause
                oder in Ihre Wohnimmobilie.
              </p>
              <p>
                Wir setzen für Ihr Unternehmen alle Anforderungen an die
                Wasserversorgung und Wasseraufbereitung um. Wir übernehmen die
                Ausstattung Ihres Neubaus mit der nötigen Haustechnik und der
                Heizungsanlage. Wir bieten Servicearbeiten und Reparaturen im
                Bad und sanieren Ihr Badezimmer für ein schöneres Wohnen. Um
                Ihre Wasser Aufbereitung zu optimieren, führen wir zuverlässige
                Wasseranalysen durch. Wir kümmern uns um Ihren Heizungsunterhalt
                für ein wirtschaftliches und zugleich wärmeoptimiertes Wohnen.
                Wir warten Ihr Abwassersystem und entkalken Ihren Boiler.
              </p>
              <p>
                Wie auch immer Ihre Wünsche und Vorstellungen aussehen, wir
                bieten Ihnen jederzeit eine faire und fundierte Beratung,
                unterbreiten ein umfassendes Angebot und planen die Umsetzung
                bis ins kleinste Detail, bevor wir die Arbeiten unter Verwendung
                hochwertiger Materialien beginnen. Wir führen Reparaturen
                schnell und zuverlässig aus, sind über unsere 24-Stunden Hotline
                immer für Sie erreichbar und in kürzester Zeit bei Ihnen vor
                Ort. Dabei können Sie sich jederzeit auf ein einwandfreies
                Auftreten unserer Mitarbeiter verlassen, die gepflegt und
                freundlich die mit unserem Firmennamen verbundene
                Qualitätsarbeit vertreten.
              </p>
            </div>
          </div>
          <div class="image">
            <img src="./../assets/img/home/service.jpg" alt="" />
          </div>
        </div>

      </div>
    </section>
  </article>
  <footer>
    <div class="contact">
      <div class="icon">
        <img src="./../assets/icon_telefon.svg" alt="" />
      </div>
      <p>
        Wir beraten Sie gerne.
      </p>
      <p>
        Rufen Sie uns an.
      </p>
      <p>
        <a href="tel:+41 71 277 15 15">+41 71 277 15 15</a>
      </p>
      <div class="image">
        <img src="./../assets/img/home/homepage_footer.jpg" alt="" />
      </div>
    </div>
  </footer>
</main>

<main>
  <header class="page-header-image">
    <div class="image">
      <img
        src="./../assets/img/headers/egli_wassertechnik_header_philosophie.jpg"
        alt=""
        class="frame"
      />
    </div>
  </header>
  <article>
    <section class="highlight">
      <div class="container">
        <h2>
          Philosophie
        </h2>
        <div class="icon">
          <img src="./../assets/icon_welt.svg" alt="" />
        </div>
        <h3>
          Grundidee
        </h3>
        <p class="col half">
          <b>
            Die kontinuierlich ansteigende Zahl der Menschen auf unserem
            Planeten fordert einen immer höheren Bedarf an sauberem Wasser. Der
            durchschnittliche Verbrauch liegt bei 160 Litern Wasser am Tag bei
            einem globalen Wasservorrat von 1.380 Millionen km³.
          </b>
        </p>
        <div class="col half">
          <p>
            Weltweit gesehen fliesst die Hälfte des in den Städten und Industrie
            produzierte Abwasser zurück in die Natur, was zwangsläufig die
            Verschmutzung von Seen, Flüssen und Grundwasser zur Folge hat. So
            haben immer weniger Menschen einen Zugang zu sauberem Trinkwasser.
            In Schwellenländern stehen laut der WHO 80 % der auftretenden
            Krankheiten in Zusammenhang mit verunreinigtem Wasser.
          </p>
          <p>
            Wir müssen erkennen, dass Wasser das wertvollste Gut der Erde ist
            und als Grundlage des gesamten Lebens eine vorrangige Stellung
            einnimmt. Die Egli Wassertechnik hat die Umsetzung einer optimalen
            Wasserversorgung zu ihrem vorrangigen Ziel erklärt.
          </p>
        </div>
      </div>
    </section>
    <section class="image">
      <img src="./../assets/img/egli_wassertechnik_image_wasser.jpg" alt="" />
    </section>
    <section class="counters">
      <div class="numbers">
        <div class="counter">
          <div class="count" data-target="1386">1'386</div>
          <div class="unit">Millionen Kubikkilometer</div>
          <div class="info">Globale Wasservorräte</div>
        </div>
        <div class="counter">
          <div>
            <span class="count" data-target="97.4">97.4</span
            ><span class="unit">%</span>
          </div>
          <div class="info">
            davon Salzwasser
          </div>
        </div>
        <div class="counter">
          <div>
            <span class="count" data-target="0.27">0.27</span
            ><span class="unit">%</span>
          </div>
          <div class="info">
            davon Trinkwasser
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <p class="col half">
          <b>
            Der größte Teil unseres blauen Planeten (etwa 70 %) ist mit Wasser
            bedeckt, was einer Menge von 1,4 Billiarden Litern entspricht. Die
            salzwasserhaltigen Weltmeere haben einen Anteil von 96,5 % am
            gesamten Wasservorkommen der Erde, was bedeutet, dass nur 3,5 % des
            Wassers als Süßwasser vorliegen. Davon ist etwas mehr als die Hälfte
            als Eis gebunden und ist einer Nutzung nicht zugänglich. Der andere
            Teil liegt als Grundwasser vor.
          </b>
        </p>
        <div class="col half">
          <p>
            H2O ist wohl eine der bekanntesten chemischen Formeln. Sie steht für
            Wasser, das aus den beiden Elementen Wasserstoff (Hydrogenium) und
            Sauerstoff (Oxygenium) besteht. Wasser ist geruchlos, geschmacklos,
            farblos und weist besondere Eigenschaften, weshalb es als Quelle und
            als Grundvoraussetzung von Leben auf der Erde und der Entstehung
            menschlicher Zivilisationen gilt.
          </p>
          <p>
            Als Bestandteil des Lebens hilft es dem Körper, lebensnotwendige
            Aufgaben zu erfüllen. Der Körper eines Erwachsenen besteht aus etwa
            70 % Wasser und um sein Funktionieren zu gewährleisten, ist Trinken
            unerlässlich. Ein erwachsener Mensch sollte täglich ungefähr zwei
            Liter Flüssigkeit zu sich nehmen.
          </p>
          <p>
            Weltweit leidet über ein Drittel der Bevölkerung an Wassermangel.
            Die Hälfte dieser Menschen hat gar keinen Zugang zu sauberem
            Trinkwasser.
          </p>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <p class="col half">
          <b>
            In unserem Leben nimmt das Wasser einen zentralen Platz ein. Der
            Regen sorgt für die nötige Trinkwasserversorgung, die Bewässerung in
            der Landwirtschaft, die Gewässer zur Fischzucht und die Flüsse zum
            Transport von Gütern. Auch die Industrie verwendet Wasser in allen
            Produktionsschritten in großen Mengen, wonach das Wasser geklärt und
            dem Kreislauf wieder zugeführt wird.
          </b>
        </p>
        <div class="col half">
          <p>
            Die Qualität des Wassers, das in unsere Leitungen fließt, regelt die
            Trinkwasserverordnung, die durch Richtwerte und Grenzwerte dessen
            erforderliche Beschaffenheit festlegt. Verschmutztes Wasser wird in
            den Wasserwerken mit Hilfe von unterschiedlichen Verfahren gereinigt
            und so wieder nutzbar gemacht.
          </p>
          <p>
            Für den einen ist Wasser eine Flüssigkeit mit fest definierten
            Charakteristika, für den anderen ein Energieproduzent. Für uns aber
            ist Wasser mehr, für uns ist Wasser das Leben selbst.
          </p>
        </div>
      </div>
    </section>
    <section class="video">
      <div class="container">
        <h2>
          Home Film
        </h2>
        <p class="col half">
          <b>
            Home ist ein beeindruckender Film, der aufzeigt wie die Menschheit
            mit unserem Heimatplaneten, dem Leben und den Ressourcen darauf
            umgeht und in Kurzsichtigkeit mehr Probleme verursacht als löst.
          </b>
        </p>
        <p class="col half">
          Der Film war für mich sehr prägend und wie die Filmcrew selbst sagte,
          kann der Impakt dieses Films nicht in Geld gemessen werden, sondern in
          Zuschauern. Da er deswegen gratis auf Youtube zur Verfügung gestellt
          wurde verlinke ich hier sehr gerne darauf.
        </p>

        <div class="video-box">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/-GUeDISwZ3E"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
  </article>
</main>

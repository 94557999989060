import { Component, OnInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})

export class HomeComponent implements OnInit {

  scrollEvent(e) {
    if (e.deltaY > 0) {
      this.nextFrame();
    } else if (e.deltaY < 0) {
      this.prevFrame();
    }
  }

  nextFrame () {
    if ($(".video .frame.active").next(".frame").hasClass("frame")) {
      $
      $(".video .frame.active").removeClass("active").next(".frame").addClass("active").removeClass("prev").prevAll(".frame").addClass("prev");
    } else {}
  }

  prevFrame () {
    if ($(".video .frame.active").prev(".frame").hasClass("frame")) {
      $(".video .frame.active").removeClass("active").prev(".frame").addClass("active").removeClass("prev");
    } else {}
  }

  toggleBox(e) {
    $(e.currentTarget).addClass("open");
    $(".services").addClass("content-open");
  }

  closeBox(e) {
    console.log("clicked close");
    $(".services .service-box.open").removeClass("open");
    $(".services").removeClass("content-open");
    e.stopPropagation();
  }

  constructor() { }

  ngOnInit() {

  }

}

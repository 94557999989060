import { Component, OnInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})


export class HeaderComponent implements OnInit {

  scrolled = false;

  toggleMenu() {
    $(".navigation").toggleClass("mobile-open");
    $(".menu-button").toggleClass("active");
  }

  closeMenu() {
    $(".navigation").removeClass("mobile-open");
    $(".menu-button").removeClass("active");
  }

  constructor() { }

  ngOnInit() {
    if (window.location.hash !== "#/home") {
      $(".main-header").addClass("open");
    }
    $(window).on("scroll", e => {
      if ($(window)[0].scrollY > 50) {
        $(".main-header").addClass("open");
      } else if (window.location.hash == "#/home"){
        $(".main-header").removeClass("open");
      }
    });
  }

}

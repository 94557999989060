import { Injectable } from "@angular/core";

import { createClient } from "contentful";

@Injectable({
  providedIn: "root",
})
export class ContentfulService {
  private cdaClient = createClient({
    space: "wri1x9e48b4o",
    accessToken: "Vcn4cKdXXr_f_T2AKCsqpRQfTTQqZ0KEA2qnkQ_DtFg",
  });

  constructor() {}

  getImagesByCategory(query) {
    return this.cdaClient
      .getEntries({
        content_type: "image",
        "fields.category[match]": query,
      })
      .then((res) => {
        return res;
      });
  }

  getContentByType(query) {
    return this.cdaClient
      .getEntries({
        content_type: query,
      })
      .then((res) => {
        return res;
      });
  }

  getAsset(id) {
    return this.cdaClient.getAsset(id).then((res) => {
      return res;
    });
  }

  optimizeImageUrl(url, width, height) {
    // height = Math.round(height) * 2;
    // width = Math.round(width) * 2;
    return `${url}?w=${width}&h=${height}&fit=fill&fm=jpg&fl=progressive`;
  }

  getEntryById(query) {
    return this.cdaClient.getEntry(query).then((res) => {
      return res;
    });
  }

  // getLoadingState() {
  //   return this.loading;
  // }

  // reportError(errormessage: string) {
  //   console.log(errormessage);
  //   this.loadingError = true;
  // }

  // setLoadingState(state: boolean) {
  //   this.loading = state;
  // }
}

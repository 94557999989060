import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';

declare var $: any;

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.sass'],
})
export class ReferencesComponent implements OnInit {

  constructor(private contentful: ContentfulService) {}

  referenceImages: any;

  clients: any;

  partners: any;
  toggleLightbox(e) {
    $(e.currentTarget).toggleClass('lightbox');
    $('article').toggleClass('in-front');
    $('.lightbox-background, .lightbox-close-button').toggleClass('show');
  }

  closeLightbox() {
    $('.lightbox').removeClass('lightbox');
    $('article').removeClass('in-front');
    $('.lightbox-background, .lightbox-close-button').removeClass('show');
  }

  ngOnInit() {
    this.contentful.getImagesByCategory('references').then((data: any) => {
      data.items.forEach((item) => {
        item.fields.image.fields.file.url = this.contentful.optimizeImageUrl(
          item.fields.image.fields.file.url,
          1920,
          1280
        );
      });
      this.referenceImages = data.items.sort((a, b) => a.fields.order - b.fields.order);
    });
    this.contentful.getImagesByCategory('clients').then((data: any) => {
      this.clients = data.items.sort((a, b) => a.fields.order - b.fields.order);
      console.log(this.clients);
    });
    this.contentful.getImagesByCategory('partners').then((data: any) => {
      this.partners = data.items.sort(function (a, b) {
        return a.fields.order - b.fields.order;
      });
    });
  }
}

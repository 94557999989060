<header class="main-header">
  <div class="container">
    <h2 class="logo" routerLink="/home">
      <img src="assets/logo.svg" alt="">
    </h2>
    <div class="menu-button" (click)="toggleMenu()">
      <div class="line"></div>
    </div>
    <nav class="navigation">
      <ul>
        <li>
          <a routerLink="/home" routerLinkActive="active" (click)="closeMenu()">Home</a>
        </li>
        <li>
          <a routerLink="/unternehmen" routerLinkActive="active" (click)="closeMenu()" >Unternehmen</a>
        </li>
        <li>
          <a routerLink="/philosophie" routerLinkActive="active" (click)="closeMenu()" >Philosophie</a>
        </li>
        <li>
          <a routerLink="/dienstleistungen" routerLinkActive="active" (click)="closeMenu()" >Dienstleistungen</a>
        </li>
        <li>
          <a routerLink="/referenzen" routerLinkActive="active" (click)="closeMenu()" >Referenzen</a>
        </li>
        <li>
          <a routerLink="/kontakt" routerLinkActive="active" (click)="closeMenu()"  class="cta">Kontakt</a>
        </li>
      </ul>
    </nav>
  </div>
</header>

<footer>
  <div class="container">
    <div class="col half">
      <div class="logo">
<!--        <img src="./../assets/logo.svg" alt="">-->
        <img src="./../assets/img/egli_wassertechnik_10_jahre.jpeg" alt="">
      </div>
    </div>
    <div class="col half">
      <h3 class="full">
        Egli Wassertechnik
      </h3>
      <p class="third">
        St.Gallerstrasse 36<br>
        9032 Engelburg
      </p>
      <p class="two-third">
        <a href="tel:+41 71 277 15 15">+41 71 277 15 15</a><br>
        <a href="mailto:info@egli-wassertechnik.ch">info@egli-wassertechnik.ch</a>
      </p>
    </div>
    <div class="col half impressum">
      ©2019 – Egli-Wassertechnik
    </div>
    <div class="col half impressum">
      <div>
        <p>
          Design & Bilder: <a href="mailto:daenj@me.com">daenj@me.com</a>
        </p>
        <p>
          Entwicklung: <a href="https://badsam.ch">badsam</a>
        </p>
      </div>
    </div>
  </div>
</footer>

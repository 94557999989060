<main>
  <header class="page-header-image">
    <div class="image">
      <img
        src="./../assets/img/headers/egli_wassertechnik_header_dienstleistungen.jpg"
        alt=""
        class="frame"
      />
    </div>
  </header>
  <article>
    <section class="highlight">
      <div class="container">
        <h2>
          Dienstleistungen
        </h2>
        <div class="service col third">
          <div class="header">
            <div class="icon">
              <img src="./../assets/icon_haustechnik.svg" alt="" />
            </div>
            <h3>
              Haustechnik
            </h3>
          </div>
          <p>
            Als Ihr zuverlässiger Partner für Installationen im Bereich Haus und
            Wasser bieten wir fair und effizient einen erstklassigen Service von
            der Planung bis zur Umsetzung.
          </p>
        </div>
        <div class="service col third">
          <div class="header">
            <div class="icon">
              <img src="./../assets/icon_wassertechnik.svg" alt="" />
            </div>
            <h3>
              Wassertechnik
            </h3>
          </div>
          <p>
            Mit Blick auf eine einwandfreie Trinkwasserqualität und nach dem
            Motto „Wasser ist Leben“ sorgen wir für optimales Trinkwasser, das
            Ihren Ansprüchen gerecht wird.
          </p>
        </div>
        <div class="service col third">
          <div class="header">
            <div class="icon">
              <img src="./../assets/icon_beratung.svg" alt="" />
            </div>
            <h3>
              Beratung
            </h3>
          </div>
          <p>
            Als Ihr kompetenter Partner mit langjähriger Berufserfahrung sind
            wir rund um die Uhr für Sie da und beraten Sie jederzeit gern.
          </p>
        </div>
      </div>
    </section>
    <section class="image">
      <img src="./../assets/img/4E2A0210_TEST.jpg" alt="" />
    </section>
    <section>
      <div class="container">
        <div
          class="col half service-box"
          *ngFor="let service of services"
          [class.highlight]="service.fields.tags?.includes('highlight')"
        >
          <div class="background" *ngIf="service.fields.images">
            <img [src]="service.fields.images[0].fields.file.url" alt="" />
          </div>
          <div class="header" (click)="toggleServiceOpen($event)">
            <div class="icon" *ngIf="service.fields.icon">
              <img [src]="service.fields.icon.fields.file.url" alt="" />
            </div>

            <h2>
              <span *ngIf="service.fields.tags?.includes('new')">Neu: </span
              >{{ service.fields.title }}
            </h2>
          </div>
          <p>
            {{ service.fields.description }}
          </p>
          <p>
            <a [href]="service.fields.url" *ngIf="service.fields.url">{{
              service.fields.url
            }}</a>
          </p>
        </div>
      </div>
    </section>
  </article>
</main>

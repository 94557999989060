import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { PhilosophyComponent } from "./philosophy/philosophy.component";
import { ReferencesComponent } from "./references/references.component";
import { ServicesComponent } from "./services/services.component";
import { ServiceDetailComponent } from "./service-detail/service-detail.component"
import { ContactComponent } from "./contact/contact.component";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent
  },
  {
    path: "unternehmen",
    component: AboutComponent
  },
  {
    path: "philosophie",
    component: PhilosophyComponent
  },
  {
    path: "dienstleistungen",
    component: ServicesComponent
  },
  {
    path: "dienstleistungen/:id",
    component: ServiceDetailComponent
  },
  {
    path: "referenzen",
    component: ReferencesComponent
  },
  {
    path: "kontakt",
    component: ContactComponent
  },
  {
    path: "**",
    redirectTo: "home",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

<main>
  <header class="page-header-image">
    <div class="image">
      <img
        src="./../assets/img/headers/egli_wassertechnik_header_referenzen.jpg"
        alt=""
        class="frame"
      />
    </div>
  </header>
  <article>
    <div class="lightbox-background" (click)="closeLightbox()"></div>
    <div
      class="lightbox-close-button close-button"
      (click)="closeLightbox()"
    ></div>
    <section class="highlight">
      <div class="container">
        <h2>
          Referenzen
        </h2>
        <div class="icon">
          <img src="./../assets/icon_welt.svg" alt="" />
        </div>
        <h3>
          &laquo; Ein starkes Netzwerk ist uns wichtig, denn Qualität verbindet
          &raquo;
        </h3>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur autem, vitae cupiditate exercitationem facere, reiciendis laborum eligendi, repudiandae eveniet ratione tenetur hic harum adipisci odio assumenda soluta repellendus, corporis reprehenderit.
        </p> -->
      </div>
    </section>
    <section class="references">
      <h2>
        Unsere Referenzen
      </h2>
      <div class="container">
        <div
          class="col third image"
          *ngFor="let image of referenceImages"
          (click)="toggleLightbox($event)"
        >
          <img
            [src]="image.fields.image.fields.file.url"
            [alt]="image.fields.image.fields.file.url"
          />
        </div>
      </div>
    </section>
<!--    <section class="highlight">-->
<!--      <h2>-->
<!--        Unsere Kunden-->
<!--      </h2>-->
<!--      <div class="container logos">-->
<!--        <ng-container *ngFor="let client of clients">-->
<!--          <ng-container *ngIf="client.fields.url">-->
<!--            <a-->
<!--              [href]="client.fields.url"-->
<!--              target="_blank"-->
<!--              rel="noopener"-->
<!--              class="logo"-->
<!--            >-->
<!--              <div class="image">-->
<!--                <img-->
<!--                  [src]="client.fields.image.fields.file.url"-->
<!--                  [alt]="client.fields.image.fields.file.url"-->
<!--                />-->
<!--              </div>-->
<!--            </a>-->
<!--          </ng-container>-->
<!--          <ng-container *ngIf="!client.fields.url">-->
<!--            <div class="logo">-->
<!--              <div class="image">-->
<!--                <img-->
<!--                  [src]="client.fields.image.fields.file.url"-->
<!--                  [alt]="client.fields.image.fields.file.url"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-container>-->
<!--        </ng-container>-->
<!--      </div>-->
<!--    </section>-->
    <section>
      <h2>
        Unsere Partner
      </h2>
      <div class="container logos">
        <ng-container *ngFor="let partner of partners">
          <a
            [href]="partner.fields.url"
            target="_blank"
            rel="noopener"
            class="logo"
          >
            <div class="image">
              <img
                [src]="partner.fields.image.fields.file.url"
                [alt]="partner.fields.image.fields.file.url"
              />
            </div>
          </a>
        </ng-container>
      </div>
    </section>
  </article>
</main>

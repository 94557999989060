import { Component, OnInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'app-philosophy',
  templateUrl: './philosophy.component.html',
  styleUrls: ['./philosophy.component.sass']
})
export class PhilosophyComponent implements OnInit {

  count (target, index) {

    var count = target.find(".count");

    var number = count.data("target");

    var num = 0;

    var i = 0;

    this.intervals[index] = window.setInterval(e => {
      if (num < number) {
        if (num + 100 < number) {
          num += 100;
        } else if (num + 10 < number) {
          num += 10;
        } else if (num + 1 < number) {
          num += 1;
        } else if (num + 0.1 < number) {
          num += 0.1;
        } else if (num + 0.01 < number) {
          num += 0.01;
        }
        count.html(Math.ceil(num * 100) / 100);
      } else {
        window.clearInterval(this.intervals[index]);
      }
      i ++;
    }, 80);

    console.log(number);
  }

  scrolledpast;

  intervals = [];

  // onscroll (e) {
  //   console.log(e);
  // }

  constructor() { }

  ngOnInit() {

    $("main").on("mousewheel", e => {
      if (!this.scrolledpast) {
        var x = $(".numbers")[0].offsetTop;
        var y = $(window)[0].scrollY + (window.innerHeight * 0.75);

        if (x < y ) {
          this.scrolledpast = true;
          $(".numbers .counter").each( e => {
            window.setTimeout(a => {
              this.count($(".numbers .counter:nth-of-type(" + (e + 1) + ")"), e);
            }, e * 250);
          })
          // this.count($(".numbers"));
        }
      }
    });

  }

}

<main>
  <header class="page-header-image">
    <div class="image">
      <img src="./../assets/img/headers/egli_wassertechnik_header_kontakt.jpg" alt="" class="frame">
    </div>
  </header>
  <article>
    <section class="highlight">
      <div class="container">
        <h2>
          Kontakt
        </h2>
        <div class="icon">
          <img src="./../assets/icon_welt.svg" alt="">
        </div>
        <h3>
          &laquo;Wir beraten Sie gerne.&raquo;
        </h3>
        <p>
          Wir sind in einem breiten Umkreis um Zürich und die Ostschweiz tätig und spezialisieren uns auf Grossprojekte. Zögern Sie daher nicht, uns zu kontaktieren um gemeinsam über Ihr Projekt zu sprechen.
        </p>
      </div>
    </section>

    <section class="contact">
      <div class="icon">
        <img src="./../assets/icon_telefon.svg" alt="">
      </div>
      <h3 class="mobile-hidden">
        Kontaktieren Sie uns direkt
      </h3>
      <h3>
        <a href="tel: +41 71 277 15 15">T +41 (0)71 277 15 15</a>
      </h3>
      <h3>
        <a href="info@egli-wassertechnik.ch">info@egli-wassertechnik.ch</a>
      </h3>
    </section>

    <!-- <section>
      <h2>
        Kontaktieren Sie uns
      </h2>
      <div class="container">
        <div class="form">
          <div class="half">
            <input type="text" name="vorname" placeholder="Vorname">
            <input type="text" name="nachname" placeholder="Name">
            <input type="text" name="telefon" placeholder="Telefon">
            <input type="text" name="mail" placeholder="E-Mail">
          </div>
          <div class="half">
            <input type="text" name="firma" placeholder="Firma">
            <textarea name="kommentar" placeholder="Kommentar" rows="7" cols="80"></textarea>
          </div>
        </div>
      </div>
    </section> -->

    <section>
      <h2>
        So finden Sie uns
      </h2>
      <div>
        <div class="map-box">
          <div class="text">
            <div class="icon">
              <img src="./../assets/icon_welt.svg" alt="">
            </div>
            <h3>
              Egli<br>
              Wassertechnik
            </h3>
            <p>
              St.Gallerstrasse 36<br>
              9032 Engelburg
            </p>
            <p>
              <a href="tel: +41 71 277 15 15">T +41 (0)71 277 15 15</a>
              <a href="info@egli-wassertechnik.ch">info@egli-wassertechnik.ch</a>
            </p>
          </div>
          <div class="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2698.483272381759!2d9.344803115619742!3d47.441518279174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ae1dfd6eac55f%3A0x6e2fc5977307a8b!2sEgli+Wassertechnik!5e0!3m2!1sen!2sch!4v1556667163163!5m2!1sen!2sch" frameborder="0" style="border:0" allowfullscreen="false"></iframe>
          </div>
        </div>
<!--        <div class="map-box">-->
<!--          <div class="text">-->
<!--            <div class="icon">-->
<!--              <img src="./../assets/icon_welt.svg" alt="">-->
<!--            </div>-->
<!--            <h3>-->
<!--              Zweigniederlassung<br>-->
<!--              Laufenburg-->
<!--            </h3>-->
<!--            <p>-->
<!--              Baslerstrasse 15<br>-->
<!--              5080 Laufenburg-->
<!--            </p>-->
<!--            <p>-->
<!--              <a href="tel: +41 61 863 00 92">T +41 (0)61 863 00 92</a>-->
<!--              <a href="info@egli-wassertechnik.ch">info@egli-wassertechnik.ch</a>-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="map">-->
<!--            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2691.87578005906!2d7.90652081562427!3d47.57020527918219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47904dfc5ea59eb3%3A0xa54c9cc8ec5c5e2c!2sEgli+Wassertechnik+Wallbach!5e0!3m2!1sen!2sch!4v1556667407278!5m2!1sen!2sch" frameborder="0" style="border:0" allowfullscreen="false"></iframe>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </section>

    <section class="image">
      <img src="./../assets/img/egli-wassertechnik_driving_car_fast_service.jpg" alt="">
    </section>
    <section>
      <h2>
        Wir freuen uns auf Ihren Anruf
      </h2>
    </section>
  </article>
</main>

<main>
  <header class="page-header-image">
    <div class="image">
      <img
        src="./../assets/img/headers/egli_wassertechnik_header_unternehmen.jpg"
        alt=""
        class="frame"
      />
    </div>
  </header>
  <article>
    <section class="highlight">
      <div class="container">
        <h2>
          Unternehmen
        </h2>
        <div class="icon">
          <img src="./../assets/icon_welt.svg" alt="" />
        </div>
        <h3>
          &laquo; Wasser ist der Ursprung allen Lebens,<br />
          Teil von uns und wir Teil davon. &raquo;
        </h3>
        <p class="col half">
          <b>
            2010 gründete Patrick Egli, gelernter Spengler- Sanitär Installateur
            und ausgebildeter Wasserfachmann der Aqua Suisse, die Einzelfirma
            Egli Wassertechnik mit Sitz in Engelburg. Als zuverlässiger und
            kompetenter Partner im Sanitärbereich erwarb sich die Firma
            innerhalb kürzester Zeit einen guten Namen bei Privatkunden sowie
            bei Hausverwaltungen.
          </b>
        </p>
      </div>
    </section>
<!--    <section class="team">-->
<!--      <h2 class="title">-->
<!--        <div class="text">Unser Team</div>-->
<!--      </h2>-->
<!--      <div class="team-container">-->
<!--        <div-->
<!--          class="close-button close-team-member-button"-->
<!--          (click)="closeTeamMember()"-->
<!--        ></div>-->
<!--        <div class="team-member" (click)="openBox($event)">-->
<!--          <div class="image">-->
<!--            <img-->
<!--              src="./../assets/img/team/egli-wassertechnik_patrick_egli.jpg"-->
<!--              alt=""-->
<!--            />-->
<!--          </div>-->
<!--          <div class="content">-->
<!--            <div class="text">-->
<!--              <h3>-->
<!--                Patrick Egli-->
<!--              </h3>-->
<!--              <h4>-->
<!--                Inhaber/Geschäftsführer-->
<!--              </h4>-->
<!--              <a href="tel: +41 78 842 27 99">+41 78 842 27 99</a>-->
<!--              <a href="mailto: patrick@egli-wassertechnik.ch"-->
<!--                >patrick@egli-wassertechnik.ch</a-->
<!--              >-->
<!--              <p>-->
<!--                "Meine anspruchsvollen und vielseitigen Lehrjahre als Spengler- -->
<!--                Sanitärinstallateur prägen bis heute meine zuverlässige und-->
<!--                korrekte Arbeitsweise. Die Qualifikation zum Wasserfachmann bei-->
<!--                der Aqua-Suisse verhalf mir später zur Gründung des eigenen-->
<!--                Geschäfts, auf das ich heute sehr stolz sein kann."-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="team-member" (click)="openBox($event)">-->
<!--          <div class="image">-->
<!--            <img-->
<!--              src="./../assets/img/team/egli-wassertechnik_andreas_allgaier.jpg"-->
<!--              alt=""-->
<!--            />-->
<!--          </div>-->
<!--          <div class="content">-->
<!--            <div class="text">-->
<!--              <h3>-->
<!--                Andreas Allgaier-->
<!--              </h3>-->
<!--              <h4>-->
<!--                Fillialleiter Wallbach/Planung-->
<!--              </h4>-->
<!--              <a href="tel: +41 76 425 27 99">+41 76 425 27 99</a>-->
<!--              <a href="mailto: andreas@egli-wassertechnik.ch"-->
<!--                >andreas@egli-wassertechnik.ch</a-->
<!--              >-->
<!--              <p>-->
<!--                "Meine vielseitige Fachkompetenz im Bereich Wasseraufbereitung-->
<!--                und Industrieinstallationen ist bei unserer Kundschaft stehts-->
<!--                gefragt. Die langjährige Erfahrung als Wasserfachmann hat mir-->
<!--                ein breites Wissen in Planung und Ausführung komplexer Anlagen-->
<!--                verschafft."-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="team-member" (click)="openBox($event)">-->
<!--          <div class="image">-->
<!--            <img-->
<!--              src="./../assets/img/team/egli-wassertechnik_duschjan_oszczak.jpg"-->
<!--              alt=""-->
<!--            />-->
<!--          </div>-->
<!--          <div class="content">-->
<!--            <div class="text">-->
<!--              <h3>-->
<!--                Duschjan Oszczak-->
<!--              </h3>-->
<!--              <h4>-->
<!--                Teamleitender Monteur-->
<!--              </h4>-->
<!--              <a href="tel: +41 76 454 27 99">+41 76 454 27 99</a>-->
<!--              <a href="mailto: duschjan@egli-wassertechnik.ch"-->
<!--                >duschjan@egli-wassertechnik.ch</a-->
<!--              >-->
<!--              <p>-->
<!--                "Als ausgebildeter Sanitär- Installateur mit langjähriger-->
<!--                Berufserfahrung stehe ich für präzises, verantwortungsbewusstes-->
<!--                und termingerechtes Arbeiten. Ich schätze die langjährige-->
<!--                Zusammenarbeit mit unseren namhaften Kunden, für die wir bereits-->
<!--                zahlreiche Projekte gemeinsam realisiert haben."-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="team-member" (click)="openBox($event)">-->
<!--          <div class="image">-->
<!--            <img-->
<!--              src="./../assets/img/team/egli-wassertechnik_sven_allgaier.jpg"-->
<!--              alt=""-->
<!--            />-->
<!--          </div>-->
<!--          <div class="content">-->
<!--            <div class="text">-->
<!--              <h3>-->
<!--                Sven Allgaier-->
<!--              </h3>-->
<!--              <h4>-->
<!--                Monteur-->
<!--              </h4>-->
<!--              <a href="tel: +41 76 575 27 99 ">+41 76 575 27 99 </a>-->
<!--              <a href="mailto: sven@egli-wassertechnik.ch"-->
<!--                >sven@egli-wassertechnik.ch</a-->
<!--              >-->
<!--              <p>-->
<!--                "Mit 10-Jähriger Berufserfahrung als gelernter Sanitär und-->
<!--                Heizungsinstallateur ist es sehr spannend in einem starken Team-->
<!--                anspruchsvolle Arbeiten für namhafte Kunden auszuführen. Diese-->
<!--                Erfahrung bereichert mich von Tag zu Tag, und man wächst-->
<!--                gemeinsam."-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="team-member" (click)="openBox($event)">-->
<!--          <div class="image">-->
<!--            <img-->
<!--              src="./../assets/img/team/egli-wassertechnik_mantana_maurer.jpg"-->
<!--              alt=""-->
<!--            />-->
<!--          </div>-->
<!--          <div class="content">-->
<!--            <div class="text">-->
<!--              <h3>-->
<!--                Mantana Maurer-->
<!--              </h3>-->
<!--              <h4>-->
<!--                Back Office/Buchhaltung-->
<!--              </h4>-->
<!--              <a href="tel: +41 78 842 27 99">+41 71 277 15 15</a>-->
<!--              <a href="mailto: patrick@egli-wassertechnik.ch"-->
<!--                >mantana@egli-wassertechnik.ch</a-->
<!--              >-->
<!--              <p>-->
<!--                "Die Ausbildung zur Chemielaborantin bei der EAWAG hat mir einen-->
<!--                wichtigen Einblick im Bereich der Wasseranalyse verschafft.-->
<!--                Jetzt arbeite ich schon über zehn Jahre an der ETH Zürich und-->
<!--                freue mich auf neue Herausforderungen im Bereich Office und-->
<!--                Buchhaltung bei der Firma Egli Wassertechnik."-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
  </article>
</main>

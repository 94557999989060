import { Component, OnInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass']
})
export class AboutComponent implements OnInit {

  openBox(e) {
    $(e.currentTarget).addClass("open");
    $(".team-container").addClass("open")
    $(".close-team-member-button").addClass("show");
  }

  closeTeamMember() {
    $(".team-container .team-member").removeClass("open");
    $(".team-container").removeClass("open");
    $(".close-team-member-button").removeClass("show");
  }

  constructor() { }

  ngOnInit() {
  }

}
